/*.v2_footer_section {
	height: 30vw;
	background-color: var(--light-grey-color);
}*/


.v2_footer_section{
	width: 100vw;
	background-color: var(--light-grey-color);

	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
}

.v2_footer_section .v2_footer_btn_section{
	padding: 0 5vw;

	display: flex;
	flex-flow: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 8vw;
}

.v2_footer_section .v2_footer_btn_section .v2_footer_btn_list{
	display: flex;
	flex-flow: column;
	padding: 3vw 0;
	min-width: 80px;
}

.v2_footer_btn_list h4{
	text-align: left;
	border: 0;
	background-color: transparent;
	padding: 1vw 0;
	margin: 0;
	font-weight: 700;
	font-size: var(--nav-font);
}

.v2_footer_btn_list button{
	text-align: left;
	border: 0;
	background-color: transparent;
	color: var(--dark-color);
	padding: 8px 0;
	margin: 0;
	font-weight: 400;
	font-size: var(--nav-font);
}

.v2_footer_btn_list button:hover span{
	border-bottom: 1px var(--dark-color) solid;
}

.v2_footer_section .v2_copyright_section{
	width: 100vw;
	height: 5vw;
	min-height: 50px;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: var(--black-color);
	color: var(--white-color);
}

.v2_copyright_section{
	font-size: var(--nav-font);
}

.v2_copyright_section button{
	font-size: var(--nav-font);
	background-color: transparent;
	color: var(--white-color);
	margin-left: 20px; 
}

.v2_copyright_section button:hover span{
	border-bottom: 1px var(--white-color) solid;
}














@media screen and (max-width:780px) {
	.v2_footer_section .v2_footer_btn_section{
		padding: 10vw 80px;
		gap: 8vw;
	}
}


@media screen and (max-width:420px) {
	
}






